import loadingStore from '../store/loading';
import { RouteLocationNormalized } from 'vue-router';
import { useWorkspaceStore, actualWorkspace, workspaceAuthorize, workspaceId } from '@/store/workspaces';
import { ref } from 'vue';
import { useInteractionsStore } from '@/store/interactions';

export const authoriseRunning = ref(false);
export const pageBeforeDisable = ref('dashboard');

const userId = localStorage.getItem('userId');

export async function authorize(to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) {
  loadingStore.startLoading();
  authoriseRunning.value = true;

  const workspaceStore = useWorkspaceStore();

  await workspaceStore.getUserPermissions();

  // BESOIN DE FETCH LES WORKSPACES | NO WORKSPACE OU LOGIN
  if (!actualWorkspace.value || from.name === 'login') {
    await workspaceStore.getWorkspaceList().catch((error) => {
      console.error('Error fetching user permissions:', error);
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
    });
  }

  // NO WORKSPACE
  if (workspaceStore.workspacesList.length === 0) {
    if (to.name === 'profile' || to.name === 'invoices') {
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next();
    } else {
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next({ name: 'no-workspace' });
    }
  }

  // PAGE ONLY ACCESS OWNER && NO OWNER
  if (to.meta.noOwnerRedirect && actualWorkspace.value.owner_id !== userId) {
    authoriseRunning.value = false;
    loadingStore.stopLoading();
    if (to.meta.noOwnerRedirect.toString()) {
      return next({ name: to.meta.noOwnerRedirect.toString()})
    }
    return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
  }
  
  // WORKSPACE DISABLED && PAGE INACCESSIBLE
  if (actualWorkspace.value.disable && !to.meta.authorizeDisable) {
    pageBeforeDisable.value = from.name?.toString() === 'disable' ? 'dashboard' : from.name?.toString() || 'dashboard';
    authoriseRunning.value = false;
    loadingStore.stopLoading();
    return next({ name: 'disable', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
  }
  else if (!actualWorkspace.value.disable && to.name === 'disable') {
    
    authoriseRunning.value = false;
    loadingStore.stopLoading();
    return next({ name: pageBeforeDisable.value });
  }

  // RECUP PERMISSIONS CAR CHANGEMENT DE WORKSPACE / USER
  if (workspaceAuthorize.value.workspace_id !== workspaceId.value || workspaceAuthorize.value.user_id !== localStorage.getItem('userId')) {
    await workspaceStore.getUserPermissions().catch((error) => {
      console.error('Error fetching user permissions:', error);
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
    });
  }

  if (workspaceAuthorize.value.permissions.includes('FUNCTIONS_READ')) {
    const interactionStore = useInteractionsStore();
    await interactionStore.getFunctions().catch((error) => {
      console.error('Error fetching custom functions:', error);
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
    });
  }

  const permission = to.meta.requiredAuth ? to.meta.requiredAuth.toString() : '';

  if (workspaceAuthorize.value.permissions.includes(permission) || permission === '') {
    authoriseRunning.value = false;
    loadingStore.stopLoading();
    return next();
  } else {
    authoriseRunning.value = false;
    loadingStore.stopLoading();
    return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
  }
}
