import { Requester } from '../requester';
import { KickType, FunctionType } from '@/store/interactions';

export class Interactions {
    static async kickPlayer(data: KickType){
        const kickRequester = new Requester('/interactions/kick-player', true, true);
        await kickRequester.post(data)
    }

    static async findPlayer(id: string){
        const findRequester = new Requester('/interactions/find-player', false, true);
        const params = new URLSearchParams({'player_id': id}).toString();
        return await findRequester.get(params)
    }

    static async notifyServers(message: string){
        const requester = new Requester('/interactions/notify-servers', true, true);
        await requester.post({'message': message})
    }

    static async createFunction(func: FunctionType){
        const requester = new Requester('/functions', true, true);
        return await requester.post(func)
    }

    static async getFunctions(): Promise<FunctionType[]> {
        const requester = new Requester('/functions', false, true);
        return await requester.get()
    }

    static async updateFunction(func: FunctionType){
        const requester = new Requester('/functions', true, true);
        await requester.patch(func)
    }

    static async deleteFunction(id: string){
        const requester = new Requester('/functions', true, true);
        await requester.delete({'_id': id})
    }

    static async triggerFunction(func: FunctionType){
        const requester = new Requester('/functions/run', true, true);
        await requester.post(func)
    }
}